
    import Vue from "vue";
    import Component from 'vue-class-component';
    import ApiButton from "@/components/ApiButton.vue";
    import DatePicker from "@/components/DatePicker.vue";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import * as toastr from "toastr";

    @Component({ components: { ApiButton, DatePicker } })
    export default class NonWorkingDays extends Vue {

        mounted() {
            this.search();
        }
        
        //
        // -- properties
        //

        nonWorkingDays: Array<Date> = [];
        editableDate: Date|null = null;

        //
        // -- methods
        //

        async search() {
            this.nonWorkingDays = await apiClient.get("api/nonWokringDay/search");
        }

        async doSave(event: Event) {
            if (!utils.hasDateValue(this.editableDate)) return;
            const response: { success: boolean } = await apiClient.post("api/nonWokringDay/save", { date: this.editableDate }, event);
            if (response.success) {
                this.editableDate = null;
                this.search();
            }
            else {
                toastr.error("Failed to save date");
            }
        }

        async doDelete(date: Date, event: Event) {
            const response: { success: boolean } = await apiClient.post("api/nonWokringDay/delete", { date: date }, event);
            if (response.success) {
                this.search();
            }
            else {
                toastr.error("Failed to save date");
            }
        }

    }
